import useImage from "../hooks/useImage";

export default function Hero({ main }) {
  const backgroundImage = useImage("assets/img/helen-main.jpg");

  const sectionStyle = {
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: '5rem',
    paddingTop: '5rem'
  };

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage.image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.7,
    zIndex: 0
  };

  const contentStyle = {
    position: 'relative',
    zIndex: 1
  };

  return (
    <section className="background-right-top" style={sectionStyle}>
      <div style={backgroundStyle}></div>
      <div className="cont container mb-5 mt-5 pb-5 pt-5" style={contentStyle}>
        <div className="align-items-center mb-5 mt-5 pb-5 pt-5 row">
          <div className="col-md-10 col-xl-7">
            <h1 className="display-4 fw-bold text-capitalize text-dark">
              <span className="d-block fw-normal">Dra. Helen Furlan Torina</span>
              Médica Psiquiatra
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}
