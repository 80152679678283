import BooksNew from "../components/BooksNew";
import ContentHeader from "../components/ContentHeader";

export default function Treatments() {
  return (
    <main>
      <ContentHeader header="Livros"></ContentHeader>
      <BooksNew ></BooksNew>
    </main>
  );
}
