import TipBox from "../components/TipBox";
import tips from "../data/tips.json";
import ContentHeader from "../components/ContentHeader";

export default function Tips() {
  return (
    <main>
      <ContentHeader subheader="DICAS" header="Atividades recomendadas"></ContentHeader>
      <TipBox data={tips.activities}/>
    </main>
  );
}
