import { useState } from 'react';
import emailjs from 'emailjs-com';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'helen_psiq_test', // Replace with your EmailJS service ID
      'template_qz9d0sg', // Replace with your EmailJS template ID
      formData,
      'Fx3L38pOmz5TRn9gp' // Replace with your EmailJS user ID
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Email sent successfully!');
    }).catch((err) => {
      console.error('FAILED...', err);
      alert('Failed to send email.');
    });
  };

  return (
    <section className="bg-white pb-5 pt-5">
      <div className="container pb-4 pt-4">
        <div className="align-items-center row">
          <div className="col-lg-5 py-3">
            <h2 className="fw-bold h4 mb-4 text-uppercase">Entre em contato</h2>
            <p className="mb-4 text-secondary">
              Você pode agendar sua teleconsulta enviando e-mail para: helenftorina.psiquiatra@gmail.com, ou agendar presencialmente através do Doctoralia do Instituto Inspire - Piracicaba.
            </p>
            <div className="mb-4 g-0 row">
              <div className="col-auto">
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  height="24"
                  width="24"
                  className="me-3 text-primary"
                >
                  <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z"></path>
                </svg>
              </div>
              <div className="col">
                <a href="mailto:helenftorina.psiquiatra@gmail.com" className="text-secondary">
                  helenftorina.psiquiatra@gmail.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ms-auto py-3">
            <div className="bg-white p-4 p-lg-5 shadow">
              <h2 className="fw-bold h4 mb-4 text-uppercase">
                Envie seu e-mail
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    name="name"
                    className="form-control rounded-0"
                    placeholder="Insira o seu nome..."
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    name="email"
                    className="form-control rounded-0"
                    placeholder="Insira o seu email..."
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    name="message"
                    className="form-control rounded-0"
                    rows="6"
                    placeholder="Sua mensagem..."
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn d-block w-100 btn-primary rounded-0 text-uppercase"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
